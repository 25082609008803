import { ResponseData, ResponseError, axiosInstance, Request, ThongBao } from '@/helpers'
const moduleName = 'my'
export const Transaction = () =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}/transaction`,req).then(ResponseData).catch(ResponseError)
}
export const Balance = () =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}/balance`,req).then(ResponseData).catch(ResponseError)
}
export const Withdraw = ({sotien,ghichu}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/withdraw`,{sotien,ghichu},req).then(ResponseData).catch(ResponseError)
}
export const Teespring = () =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}/teespring`,req).then(ResponseData).catch(ResponseError)
}
export const CreateTeespring = ({Title,Url,Email,Password}) =>{
  const req = Request();
  return axiosInstance.put(`/${moduleName}/teespring`,{Title,Url,Email,Password},req).then(ResponseData).catch(ResponseError)
}
export const CheckTeespring = ({id}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/teespring/check`,{id},req).then(ResponseData).catch(ResponseError)
}
export const SetTeespringWait = ({id}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/teespring/wait`,{id},req).then(ResponseData).catch(ResponseError)
}
export const BindMyIP = () =>{
  const req = Request();
  return axiosInstance.get(`/bind`,req).then(ResponseData).catch(e=>{
    ThongBao.Error('Không thể BIND IP')
  })
}
export const CreateOrderTeespring = (request) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/teespring/make-order`,request,req).then(ResponseData).catch(ResponseError)
}
export const CheckOrderTeespring = ({uuid}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/teespring/check-order`,{uuid},req).then(ResponseData).catch(ResponseError)
}
export const RetryOrderTeespring = ({uuid}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/teespring/retry-order`,{uuid},req).then(ResponseData).catch(ResponseError)
}

export default {
  Transaction,
  Balance,
  Withdraw,
  Teespring,
  CreateTeespring,
  CheckTeespring,
  BindMyIP,
  CreateOrderTeespring,
  CheckOrderTeespring,
  RetryOrderTeespring,
  SetTeespringWait,
}