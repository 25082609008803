import mm from 'moment-timezone';
export default {
    checkDiff(t,diff='minutes'){
        const now = mm.tz('Asia/Ho_Chi_Minh');
        const x = mm(t).tz('Asia/Ho_Chi_Minh');
        return x.diff(now,diff)
    },
    getTimeNow(){
        return mm.tz('Asia/Ho_Chi_Minh').format();
    },
    getTimeNext({min,hour,day}){
        if(min) return mm.tz('Asia/Ho_Chi_Minh').add(min,'m').format();
        if(hour) return mm.tz('Asia/Ho_Chi_Minh').add(hour,'h').format();
        if(day) return mm.tz('Asia/Ho_Chi_Minh').add(day,'d').format();
    }
}