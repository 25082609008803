<template>
  <v-dialog
    v-model="onClose"
    :max-width="width || '600px'"
    :persistent="persistent || true"
    :ransition="transition || 'dialog-bottom-transition'"
  >
    <v-card>
      <v-card-title>
        <v-btn
          class="pa-2"
          icon
          dark
          @click="doClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <span class="text-h6">{{title}}</span>
      </v-card-title>
      <v-card-text>
        <slot name="body" />
      </v-card-text>
      <v-card-actions>
        <slot name="submit" />
      </v-card-actions>
      <loading :status="loader" v-if="loader" />
    </v-card>
  </v-dialog>

</template>

<script>
import loading from '@/components/base/loading.vue';
export default {
  components:{
    loading,
  },
  props:['title','onClose','loader','width','transition','persistent'],
  methods:{
    doClose(){
      // this.onClose = false;
      this.$emit('update:onClose',false);
    }
  }
}
</script>

<style>

</style>