import { ResponseData, ResponseError, axiosInstance, Request } from '@/helpers'
const moduleName = 'kho_the'
export const GetAll = () =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}`,req).then(ResponseData).catch(ResponseError)
}
export const GetNew = () =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}/new`,req).then(ResponseData).catch(ResponseError)
}
export const RemoveThe = ({id,List}) =>{
  const req = Request();
  if(id) return axiosInstance.delete(`/${moduleName}/${id}`,req).then(ResponseData).catch(ResponseError)
  if(List) return axiosInstance.post(`/${moduleName}/remove`,{List},req).then(ResponseData).catch(ResponseError)
}
export const Import = ({Cards}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/import`,{Cards},req).then(ResponseData).catch(ResponseError)
}
export const Move = ({Cards,Owner}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/move`,{Cards,Owner},req).then(ResponseData).catch(ResponseError)
}
export const Used = (id) =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}/used/${id}`,req).then(ResponseData).catch(ResponseError)
}
export default {
  GetAll,
  GetNew,
  RemoveThe,
  Import,
  Move,
  Used,
}