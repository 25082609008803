<template>
  <v-dialog
    v-model="dialog"
    max-width="900px"
    persistent
    ransition="dialog-bottom-transition"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        :color="Color"
        dark
        v-bind="attrs"
        small
        v-on="on"
        @click="doClick"
      >
        {{ text }}
        <template v-if="icon">
          <v-icon right>
            {{ icon }}
          </v-icon>
        </template>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        <slot />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["text","title","closed","callback","color","icon"],
  data () {
    return {
      dialog:false,
    }
  },
  computed:{
    Color(){
      return this.color||"primary"
    }
  },
  watch:{
    closed(v){
      if(v !== undefined) this.dialog = v
    },
    dialog(v){
      if(this.closed !== undefined){
        this.$emit("update:closed",v)
      }
    }
  },
  methods:{
    Open(){
      this.dialog = true
      this.doClick()
    },
    doClick(){
      // console.log(typeof(this.callback))
      if(this.callback !== undefined && typeof(this.callback) === 'function') this.callback()
    }
  },
}
</script>

<style>

</style>