<template>
  <div>
      <v-card>
        <v-card-title>
          <span class="text-h6 pa-2">TEESPRING</span>
          <span class="pa-2">
            <v-btn
              color="green"
              dark
              @click="doOpenBoxAdd"
            >
              Thêm
            </v-btn>
          </span>
        </v-card-title>
        <v-card-text>
          <v-tabs
            v-model="tabs"
            fixed-tabs
            background-color="primary"
            dark
          >
            <v-tab
              class="secondary--text"
            >
              Danh sách
            </v-tab>
            <v-tab
              class="secondary--text"
            >
              Order ({{orders.length}})
            </v-tab>
            <v-tab-item>
              <Datatables
                :headers="(isTouchDevice()) ? headers_mobile:headers"
                :items="itemsWithIndex"
                :group="false"
              >
                <template #tools>
                  <v-col
                    cols="auto"
                  >
                    <v-btn
                      color="green"
                      @click="doDownload()"
                    >
                      Refresh
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="auto"
                  >
                    <v-select
                      v-model="Filter.Status"
                      :items="StatusList"
                      item-text="text"
                      item-value="val"
                      label="Lọc bởi trạng thái"
                    />
                  </v-col>  
                  <v-col
                    cols="auto"
                  >
                    <v-checkbox
                      v-model="Filter.NoOrder"
                      label="Chưa có giao dịch ?"
                      color="green"
                    />
                  </v-col> 
                </template>
                <template #[`item.Status`]="{ item }">
                  <span
                    :class="`${TeespringStatus[item.Status].c}--text text-h6`"
                  >
                    {{TeespringStatus[item.Status].t}}
                  </span>
                  <div class="text-body2 blue--text" v-if="item.Status === 'cho_thanh_toan' && item.Last.Request">
                    {{TimeAgo(item.Last.Request)}}
                  </div>
                </template>
                <template #[`item.Auto`]="{ item }">
                  <template v-if="item.Proxy">
                    <div>
                      <p>Country: {{item.Proxy.country}}</p>
                      <p>Session: {{item.Proxy.session}}</p>
                    </div>
                  </template>
                  <v-switch
                      :success-messages="(item.AutoCheckBalance.Status) ? 'Đang tự động check Balance.':''"
                      :error-messages="(!item.AutoCheckBalance.Status) ? (!item.Cookies || !item.Proxy) ? 'Tài khoản chưa gán Proxy hoặc chưa Login':'Không tự động check Balance':''"
                      :input-value="item.AutoCheckBalance.Status"
                      color="indigo"
                      label="Balance"
                      :disabled="!item.Cookies || !item.Proxy"
                      @change="doUpdateAutoCheckBalance(item)"
                  />
                  <template v-if="item.AutoCheckBalance.Last">
                    <p>Next Check: {{ThoiGian(item.AutoCheckBalance.Last)}}</p>
                  </template>
                  <template v-if="item.AutoWithdraw">
                    <p>Tự withdraw: {{ThoiGian(item.AutoWithdraw)}}</p>
                  </template>
                </template>
                <template #[`item.Orders`]="{ item }">
                  <span class="text-h6 blue--text">{{item.Orders}}</span>
                </template>
                <template #[`item.Last.Orders`]="{ item }">
                  <span class="text-h6 blue--text">{{item.Last.Orders}}</span>
                </template>
                <template #[`item.Last.Payout`]="{ item }">
                  <span v-if="item.Last.Payout === 0" class="text-h6 red--text">Chưa</span>
                  <span v-else class="text-h6 red--text">{{ThoiGian(item.Last.Payout)}}</span>
                </template>
                <template #[`item.CampEnd`]="{ item }">
                  <template v-if="item.CampEnd">
                    <strong>End:</strong> {{ThoiGian(item.CampEnd)}}
                    <br>
                    <span v-if="item.CampEndLast"><strong>Last:</strong> {{ThoiGian(item.CampEndLast)}}</span>
                  </template>
                  <span v-else class="text-h6 orange--text">Chưa có dữ liệu</span>
                </template>
                <template #[`item.LastBalance`]="{ item }">
                  <span class="text-h6 red--text" v-if="!item.LastBalance">Chưa</span>
                  <span class="text-h6 blue--text">{{item.LastBalance}}</span>
                </template>
              </Datatables>
            </v-tab-item>
            <v-tab-item>
              <Datatables
                :headers="order_headers"
                :items="ordersFixed"
                :group="false"
              >
                <template #[`item.uuid`]="{ item }">
                  <template v-if="getUser.Access === 'admin'">
                    <v-list-item router :to="`/admin/viewer?uuid=${item.uuid}`">
                          <v-list-item-content >
                              <v-list-item-title>{{item.uuid}}</v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>
                  </template>
                  <template v-else>
                    {{item.uuid}}
                  </template>
                </template>
                <template #[`item.Status`]="{ item }">
                  <template v-if="item.Status ==='pending'">
                    <template v-if="checkDiff(item.Delay,'seconds') > 0">
                      <span class="text-body2 blue--text pa-2">Đang chờ - {{ThoiGian(item.Delay)}} </span>
                    </template>
                    <template v-else>
                      <span class="text-body2 blue--text pa-2">Đang xử lí </span>
                    </template>
                    <v-progress-circular
                        indeterminate
                        color="blue"
                        :size="20"
                    />

                  </template>
                  <template v-if="item.Status ==='step1'">
                    <span class="text-body2 green--text pa-2">Đã tạo Cart </span>
                    <v-progress-circular
                      indeterminate
                      color="green"
                      :size="20"
                    />
                  </template>
                  <template v-if="item.Status ==='step2'">
                    <span class="text-body2 green--text pa-2">Đã lấy Stripe Token </span>
                    <v-progress-circular
                      indeterminate
                      color="green darken-2"
                      :size="20"
                    />
                  </template>
                  <template v-if="item.Status ==='wrong_captcha'">
                    <span class="text-body2 orange--text pa-2">Sai Captcha - Đang thử lại </span>
                    <v-progress-circular
                      indeterminate
                      color="orange"
                      :size="20"
                    />
                  </template>
                  <template v-if="item.Status ==='sock_die'">
                    <span class="text-body2 pink--text pa-2">Sock Die </span>
                    <v-progress-circular
                      indeterminate
                      color="pink"
                    />
                  </template>
                  <template v-if="item.Status ==='error'">
                    <span class="text-body2 indigo--text pa-2">Error {{(item.Message) ? `- ${item.Message}`:''}}</span>
                    <v-progress-circular
                      indeterminate
                      color="indigo"
                      :size="20"
                    />
                  </template>
                  <template v-if="item.Status ==='fail'">
                    <span class="text-body2 red--text">FAIL {{(item.Message) ? `- ${item.Message}`:''}} </span>
                  </template>
                  <template v-if="item.Status ==='ok'">
                    <span class="text-body2 green--text">OK LA LA !!!</span>
                  </template>
                </template>
              </Datatables>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    <loading :status="loading" />
    <v-dialog
      v-model="Box.Add"
      max-width="900px"
      persistent
    >
      <Header
        title="Thêm camp mới"
        :close.sync="Box.Add"
      >
        <v-card-text>
          <v-form v-model="Valid.Add">
            <v-row>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="addData.Title"
                  :rules="[required('Tên')]"
                  label="Tên"
                  hint="Không được trùng lặp"
                  persistent-hint
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="addData.Url"
                  label="Url"
                  :rules="[required('Địa chỉ')]"
                  :hint="AutoURL"
                  persistent-hint
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="addData.Email"
                  :rules="[required('Email')]"
                  label="Email"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="addData.Password"
                  :rules="[required('Mật khẩu')]"
                  label="Password"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="addData.Notes"
                  label="Ghi chú"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green"
            :disabled="!Valid.Add"
            @click="doCreate"
          >
            Thêm
          </v-btn>
        </v-card-actions>
        <loading :status="Loader.Add" />
      </Header>
    </v-dialog>
    <v-dialog
      v-model="Box.Order"
      fullscreen
      max-width="1080px"
    >
      <Header
        title="Make Order"
        :close.sync="Box.Order"
      >
        <v-card-text>
          <v-form
              v-model="Valid.Order"
          >
            <v-card flat>
              <v-card-title>
                <span class="text--h6 white--text pa-2">MAKE ORDER MAKE MONEY</span>
              </v-card-title>
              <v-card-text>
                <p class="pa-2">
                  <v-switch
                      v-model="touchDevice"
                      :value="touchDevice"
                      v-if="isTouchDevice()"
                      label="Show / Hide"
                      color="green"
                  />
                </p>
                  <v-row>
                      <v-col
                        cols="auto"
                        v-show="!touchDevice"
                      >
                        <v-text-field
                          v-model="orderData.Name"
                          label="Name"
                          :rules="[required('Tên')]"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        v-show="!touchDevice"
                      >
                        <v-text-field
                          v-model="orderData.Card"
                          label="Card"
                          :rules="[required('Card Num')]"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        v-show="!touchDevice"
                      >
                        <v-text-field
                          v-model="orderData.Expire"
                          label="Expire (MM/YY)"
                          :rules="[required('Expire')]"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        v-show="!touchDevice"
                      >
                        <v-combobox
                          v-model="orderData.Proxy"
                          label="Proxy"
                          clearable
                          :items="ports"
                          :rules="[required('Proxy')]"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        xl="4"
                        lg="4"
                        v-show="!touchDevice"
                      >
                        <v-textarea
                          v-model="orderData.Address"
                          label="Address"
                          :rules="[required('Address')]"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        v-show="!touchDevice"
                      >
                        <v-text-field
                          v-model="orderData.City"
                          label="City"
                          :rules="[required('City')]"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        v-show="!touchDevice"
                      >
                        <v-text-field
                          v-model="orderData.State"
                          label="State"
                          :rules="[required('State')]"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        v-show="!touchDevice"
                      >
                        <v-text-field
                          v-model="orderData.Zip"
                          label="Zip"
                          :rules="[required('Zip')]"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        v-show="!touchDevice"
                      >
                        <v-text-field
                          v-model="orderData.Phone"
                          label="Phone"
                          :rules="[required('Phone')]"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        v-show="!touchDevice"
                      >
                        <v-text-field
                          v-model="orderData.Country"
                          label="Country"
                          :rules="[required('Country')]"
                        />
                      </v-col>
                    <v-col
                      cols="auto"
                    >
                      <v-switch
                        v-model="orderData.DelayOrder"
                        color="green"
                        label="Delay Order"
                      />
                      <br />
                      <v-row v-if="orderData.DelayOrder">
                        <v-col
                            cols="auto"
                        >
                          <v-text-field
                              v-model="orderDelay"
                              label="Delay Min"
                              :hint="(orderData.DelayOrder) ? `Order lúc: ${ThoiGian(this.orderDelayTime)}`:'Nhập thời gian delay'"
                              persistent-hint
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      :cols="isTouchDevice() ? 6:'auto'"
                      v-if="DiaChiRandom.length > 0"
                    >
                      <v-autocomplete
                        v-if="!isTouchDevice()"
                        v-model="RandomAddressData"
                        :items="DiaChiRandomFixed"
                        item-text="text"
                        item-value="value"
                        @change="doFillAddress()"
                        label="Địa chỉ Random"
                      />
                      <v-select
                        v-else
                        v-model="RandomAddressData"
                        :items="DiaChiRandomFixed"
                        item-text="text"
                        item-value="value"
                        @change="doFillAddress()"
                        label="Địa chỉ Random"
                      />
                    </v-col>
                    <v-col
                      :cols="isTouchDevice() ? 6:'auto'"
                      v-if="KhoThe.length > 0"
                    >
                      <v-autocomplete
                        v-if="!isTouchDevice()"
                        v-model="KhoTheData"
                        :items="KhoThe"
                        item-text="Data"
                        item-value="Data"
                        @change="doKhoTheLoad"
                        label="Kho thẻ"
                      />
                      <v-select
                        v-else
                        v-model="KhoTheData"
                        :items="KhoThe"
                        item-text="Data"
                        item-value="Data"
                        @change="doKhoTheLoad"
                        label="Kho thẻ"
                      />
                    </v-col>
                  </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col :cols="isTouchDevice() ? 6:'auto'">
                    <v-btn
                        block
                        color="green"
                        :disabled="!Valid.Order"
                        @click="doOrder"
                    >
                      Make Order
                    </v-btn>
                  </v-col>
                  <v-col :cols="isTouchDevice() ? 6:'auto'">
                    <v-btn
                        block
                        color="blue"
                        @click="doResetAddress"
                    >
                      Reset Address
                    </v-btn>
                  </v-col>
                  <v-col :cols="isTouchDevice() ? 6:'auto'">
                    <v-btn
                        block
                        color="orange"
                        :loading="Loader.KhoThe"
                        :disabled="Loader.KhoThe"
                        @click="doDownloadKT"
                    >
                      Tải kho thẻ
                    </v-btn>
                  </v-col>
                  <v-col :cols="isTouchDevice() ? 6:'auto'">
                    <v-btn
                        block
                        color="green"
                        :loading="Loader.RandomAddress"
                        :disabled="Loader.RandomAddress"
                        @click="doRandomAddress"
                    >
                      Địa chỉ Random
                    </v-btn>
                  </v-col>
                  <v-col :cols="isTouchDevice() ? 6:'auto'">
                    <v-btn
                        block
                        color="green"
                        :loading="Loader.RandomAll"
                        :disabled="Loader.RandomAll"
                        @click="doRandomAddressAndCard"
                    >
                      Random All
                    </v-btn>
                  </v-col>
                  <v-col :cols="isTouchDevice() ? 6:'auto'">
                    <v-textarea
                      :value="thong_tin_random"
                    />
                    <br />
                    <v-row>
                      <v-col
                        cols="auto"
                      >
                        <v-btn
                          color="orange"
                          @click="doUsedCard"
                        >
                          Sử dụng thẻ
                        </v-btn>
                      </v-col>
                      <v-col
                          cols="auto"
                      >
                        <v-btn
                            color="red"
                            @click="doRemoveCard"
                        >
                          Xóa thẻ
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-form>

        </v-card-text>
        <loading :status="Loader.Order" />
      </Header>
    </v-dialog>
    <Modal
        :title="`Login Camp: ${CampData.Title}`"
        :onClose.sync="Box.Login"
        :loader.sync="Loader.Login"
    >
      <template #body>
        <v-form v-model="Valid.Login">
          <div class="pa-2">
            <p class="red--text">Nếu có nút <strong class="blue--text">Login w Session</strong> thì bấm trước !</p>
            <v-text-field
                v-model="CampData.Balance"
                label="Balance - Bấm đăng nhập để xem"
                :disabled="true"
            />
          </div>
          <br />
          <v-row>
            <v-col
                cols="auto"
            >
              <v-btn
                  color="blue"
                  @click="doLogin('login')"
              >
                Login
              </v-btn>
            </v-col>
            <v-col
                cols="auto"
            >
              <v-btn
                  v-if="CampData.Cookies"
                  color="blue"
                  @click="doLogin('session')"
              >
                Login w Session
              </v-btn>
            </v-col>
            <v-col
                cols="auto"
                v-if="CampData.Balance && CampData.Balance !== '$0.00'"
            >
              <v-select v-model="CampData.AutoWithdrawCurrency"
                label="Đơn vị tiền tệ"
                :items="['USD','EUR','GBP']"
              />
              <v-btn
                  color="green"
                  @click="doRequestPayout()"
              >
                Request Payout
              </v-btn>
            </v-col>
          </v-row>
          <br />
        </v-form>
      </template>
    </Modal>
    <Modal
        :title="`Thông tin tài khoản Camp: ${CampData.Title}`"
        :onClose.sync="Box.Info"
    >
      <template #body>
        <v-row>
          <v-col
              cols=6
          >
            <v-text-field
                :value="CampData.Email"
                :readonly="true"
            />

          </v-col>
          <v-col
              cols=6
          >
            <v-text-field
                :value="CampData.Password"
                :readonly="true"
            />

          </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal
        title="Tạo tài khoản TeeSpring"
        :onClose.sync="Box.Create"
    >
      <template #body>
        <v-row>
          <v-col
              cols=6
          >
            <v-text-field
              v-model="CreateAccountData.Email"
              label="Email"
            />

          </v-col>
          <v-col
              cols=6
          >
            <v-text-field
                v-model="CreateAccountData.Password"
                label="Mật khẩu"
            />

          </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal
        :title="`Lịch rút tiền: ${CampData.Title}`"
        :onClose.sync="Box.AutoPayout"
        :loader="Loader.AutoPayout"
    >
      <template #body>
          <v-responsive
            :height="AutoPayoutPickerHiding ? 150:450"
            >
            <datetime
                format="DD-MM-YYYY H:i" v-model='CampData.AutoWithdraw'
                label="Chọn thời gian"
                :callback="doPicking"
            />
            <v-select v-model="CampData.AutoWithdrawCurrency"
              label="Đơn vị tiền tệ"
              :items="['USD','EUR','GBP']"
            />
          </v-responsive>
      </template>
      <template #submit>
        <v-btn
          color="green"
          :disabled="!CampData.AutoWithdraw"
          @click="doAutoPayout"
        >
          Đặt lịch
        </v-btn>
      </template>
    </Modal>
    <Modal
        :title="`Lịch sử giao dịch: ${CampData.Title}`"
        :onClose.sync="Box.PayoutHistory"
        :loader.sync="Loader.PayoutHistory"
    >
      <template #body>
        <div v-html="CampData.History" />
      </template>
    </Modal>
	  <Modal
		  :title="`Bind IP: ${CampData.Title}`"
		  :onClose.sync="Box.BindIP"
		  :loader.sync="Loader.BindIP"
	  >
		  <template #body>
			  <v-form v-model="Valid.BindIP">
				  <v-autocomplete
					  v-model="CampData.Proxy"
					  :items="ProxyCountry"
					  item-value="value"
					  item-text="text"
					  :rules="[required('Proxy')]"
					  label="Proxy"
				  />
			  </v-form>
		  </template>
		  <template #submit>
			  <v-btn
				  color="green"
				  :disabled="!Valid.BindIP"
				  @click="doSetProxy"
			  >
				  Cập nhật
			  </v-btn>
		  </template>
	  </Modal>
  </div>
</template>

<script>
import Header from '@/components/base/pagehead.vue'
import Datatables from '@/components/base/datatables.vue'
import loading from '@/components/base/loading.vue'
import Dialog from '@/components/base/Dialog.vue'
import Modal from '@/components/base/modal.vue'
import datetime from '@/components/datetime-picker.vue'

import {mapGetters} from 'vuex'
import MyAccount from '@/modules/my.js';
import Checker from '@/modules/checker.js';
import { Toast, DoiTien, ThoiGian, ThongBao, setStore, getStore, removeItem, hasNumber, TimeAgo } from '@/helpers'
import TSClientHelper from '@/helpers/teespring/client.js'
import ClientController from '@/config/controllers/teespring/client.js'
import { TeespringStatus } from '@/helpers/status'
import validations from '@/helpers/validations'
import  headers from '@/config/headers/teespring/client.js'
import TimeHelper from '@/helpers/times.js';
import '@/main.js';
const ClientService = new TSClientHelper();
export default {
  components: {
    loading,
    Datatables,
    Header,
    Dialog,
    Modal,
    datetime,
  },
  
  data () {
    return {
      ...headers,
      AutoPayoutPickerHiding: true,
      touchDevice:true,
      AutoOrder: false,
      RandomAddressData: undefined,
      tabs: 0,
      loading: false,
      checking: false,
      checker: undefined,
      hashed: undefined,
      items: [],
      orders: [],
      orderCamp: undefined,
      orderTitle: undefined,
      addData: {},
      orderData: {
        Country:'United States',
        Proxy:'PROXYGUYS',
      },
	    ProxyCountry:[
				{value:'UnitedStates',text:'United States'},
		    {value:'Canada',text:'Canada'},
		    {value:'Germany',text:'Germany'},
		    {value:'France',text:'France'},
		    {value:'UnitedKingdom',text:'United Kingdom'},
		    {value:'Netherlands',text:'Netherlands'},
		    {value:'Italy',text:'Italy'},
		    {value:'Norway',text:'Norway'},
		    {value:'Denmark',text:'Denmark'},
		    {value:'Japan',text:'Japan'},
		    {value:'Finland',text:'Finland'},
	    ],
      orderDelay: 0,
      orderDelayTime:undefined,
      CreateAccountData:{},
      DiaChiRandom: [],
      KhoThe: [],
      KhoTheData:undefined,
      OrderCheck: {},
      ports:['PROXYGUYS','9991','9992','5100','5101','5102','5103','5104','5105'],
      Filter:{
        Status:'not_die',
        NoOrder:false,
      },
      Box: {
        Add:false,
        Order: false,
        List: false,
        Login: false,
        Info: false,
        Create: false,
        AutoPayout: false,
        PayoutHistory: false,
	      BindIP: false,
      },
      Valid:{
        Add: false,
        Order: false,
        Login: false,
	      BindIP: false,
      },
      Loader:{
        Add: false,
        Order: false,
        RandomAddress: false,
        KhoThe:false,
        Login: false,
        AutoPayout: false,
        RandomAll: false,
        PayoutHistory: false,
	      BindIP: false,
      },
      Auto: {
        CheckOrder: undefined,
      },
      StatusList:[{'val':'all','text':'Tất cả'},{'val':'not_die','text':'Ngoại trừ Die'}].concat(Object.keys(TeespringStatus).map(key=>({
        'val':key,
        'text':TeespringStatus[key].t
      }))),
      CampData:{
        AutoWithdrawCurrency:"USD"
      },
      autoCheckDelay: undefined,
      TeespringStatus,
    }
  },
  computed:{
    ...mapGetters(['getUser']),
    thong_tin_random(){
      if(!this.orderData.Card) return '';
      let data = [];
      const name = this.orderData.Name.split(' ');
      data.push(name[0]);
      data.push(name[1]);
      data.push(this.orderData.Address);
      data.push(this.orderData.City);
      data.push(this.orderData.State);
      data.push(this.orderData.Zip);
      data.push(this.orderData.Phone);
      data.push(this.orderData.Card);
      data.push(this.orderData.Expire);
      return data.join('\n');
    },
    DiaChiRandomFixed(){
      // console.log(this.DiaChiRandom);
      const items = this.DiaChiRandom.map(item=>{
        const dataItem = item.address.split('|');
        return {
          text:dataItem[0],
          value:item.address,
        }
      });
      return items;
    },
    ordersFixed(){
      return this.orders.map(
        (items) => ({
          ...items,
          controller:this.controller1(items)
        })).reverse();
    },
    AutoURL(){
      if(this.addData.Url){
        const Url = this.addData.Url.split('/')
        const UrlFormat = Url[Url.length-1]
        return `Địa chỉ sẽ là: ${UrlFormat}`
      }
      return 'Điền địa chỉ !!!'
    },
    itemsWithIndex(){
      return this.items.map(
        (items, index) => {
          const {Status,NoOrder} = this.Filter;
          if(Status === 'not_die' && items.Status === 'die') return undefined;
          if(Status !== 'all' && Status !== 'not_die' && (items.Status !== Status)) return undefined;
          if(NoOrder && (items.Last.Orders > 0)) return undefined;
        return {
          ...items,
          index: index + 1,
          SoTien: (items.Status === 'rut') ? '-'+items.SoTien:items.SoTien,
          controller:this.controller(items)
        }
      }).filter(v=>!!v)
    }
  },
  watch:{
    orders(v){
      setStore('orders',v);
    },
    tabs(v,ov){
      if(ov !== v && v === 0) this.doDownload();
    },
    AutoPayoutPickerHiding(v){
      console.log(v);
    }
  },
  methods:{
    ...MyAccount,
    ...Checker,
    ...validations,
    DoiTien,
    ThoiGian,
    TimeAgo,
    checkDiff:TimeHelper.checkDiff,
    doPicking(){
      this.AutoPayoutPickerHiding = !this.AutoPayoutPickerHiding;
    },
    doGetPayoutHistory:ClientService.doGetPayoutHistory,
    doFindTheID:ClientService.doFindTheID,
    doAutoPayoutClear:ClientService.doAutoPayoutClear,
    doAutoPayout:ClientService.doAutoPayout,
    doLogin:ClientService.doLogin,
    doRequestPayout:ClientService.doRequestPayout,
    doKhoTheLoad:ClientService.doKhoTheLoad,
    doDownloadKT:ClientService.doDownloadKT,
    doLoginOpen:ClientService.doLoginOpen,
    doViewInfo:ClientService.doViewInfo,
    doWaiting:ClientService.doWaiting,
    doResetAddress:ClientService.doResetAddress,
    doFillAddress:ClientService.doFillAddress,
    doRandomAddress:ClientService.doRandomAddress,
    doDeleteOrder:ClientService.doDeleteOrder,
    doRetryOrder:ClientService.doRetryOrder,
    doUpdateStatus:ClientService.doUpdateStatus,
    doRemoveOrder:ClientService.doRemoveOrder,
    doMergeOrder:ClientService.doMergeOrder,
    doCheckOrderExists:ClientService.doCheckOrderExists,
    doCheckOrder:ClientService.doCheckOrder,
    doOrder:ClientService.doOrder,
    doOpenOrder:ClientService.doOpenOrder,
    doCheck:ClientService.doCheck,
    AccountOwner:ClientService.AccountOwner,
    doOpenBoxAdd:ClientService.doOpenBoxAdd,
    doCreate:ClientService.doCreate,
    Callback:ClientService.Callback,
    doDownload:ClientService.doDownload,
    doUpdateAutoCheckBalance:ClientService.doUpdateAutoCheckBalance,
    doAutoPayoutOpen:ClientService.doAutoPayoutOpen,
    doCheckDelay:ClientService.doCheckDelay,
    doRandomAddressAndCard:ClientService.doRandomAddressAndCard,
    doUsedCard:ClientService.doUsedCard,
    doRemoveCard:ClientService.doRemoveCard,
	  doAddOrderManual:ClientService.doAddOrderManual,
	  doSetProxy:ClientService.doSetProxy,
	  doProxyOpen:ClientService.doProxyOpen,
    controller:ClientController.List,
    controller1:ClientController.Order,
  },
  beforeCreate() {
    this.ClientService = new TSClientHelper(this);
  },
  mounted(){
    this.touchDevice = this.isTouchDevice();
    this.doDownload();
    this.checker = setInterval(() => {
      if(this.checking === true){
        this.doDownload()
      }
    }, 10000);
    this.Auto.CheckOrder = setInterval(this.doCheckOrder, 10000);
    this.doMergeOrder();
    this.autoCheckDelay = setInterval(this.doCheckDelay,1000);
  },
  beforeDestroy(){
    clearInterval(this.checker);
    this.checker = undefined;
    clearInterval(this.Auto.CheckOrder);
    this.Auto.CheckOrder = undefined;
    if(this.autoCheckDelay){
      clearInterval(this.autoCheckDelay);
      this.autoCheckDelay = undefined;
    }
  }
}
</script>

<style>

</style>
