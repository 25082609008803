const headers = [
    { text: "STT", align: "center", sortable: true,value: "index",width:"auto",class:"primary secondary--text text-center" },
    { text: "Tên", align: "center", sortable: true,value: "Title",width:"auto",class:"primary secondary--text text-center" },
    { text: "Url", align: "center", sortable: true, value: "Url",width:"auto",class:"primary secondary--text text-center" },
    { text: "Trạng thái", align: "center", sortable: true, value: "Status",width:"auto",class:"primary secondary--text text-center" },
    { text: "Thời gian", align: "center", sortable: true, value: "CampEnd",width:"auto",class:"primary secondary--text text-center" },
    { text: "Tổng Order", align: "center", sortable: true, value: "Orders",width:"auto",class:"primary secondary--text text-center" },
    { text: "Order Cuối", align: "center", sortable: true, value: "Last.Orders",width:"auto",class:"primary secondary--text text-center" },
    { text: "Payout Cuối", align: "center", sortable: true, value: "Last.Payout",width:"auto",class:"primary secondary--text text-center" },
    { text: "Balance Cuối", align: "center", sortable: true, value: "LastBalance",width:"auto",class:"primary secondary--text text-center" },
    { text: "Auto", align: "center", sortable: true, value: "Auto",width:"auto",class:"primary secondary--text text-center" },
    { text: "Thao tác", align: "center", sortable: false, value: "controller",width:"auto",class:"primary secondary--text text-center" },
]
const headers_mobile = [
    { text: "STT", align: "center", sortable: true,value: "index",width:"auto",class:"primary secondary--text text-center" },
    { text: "Tên", align: "center", sortable: true,value: "Title",width:"auto",class:"primary secondary--text text-center" },
    { text: "Order Cuối", align: "center", sortable: true, value: "Last.Orders",width:"auto",class:"primary secondary--text text-center" },
    { text: "Balance Cuối", align: "center", sortable: true, value: "LastBalance",width:"auto",class:"primary secondary--text text-center" },
    { text: "Auto", align: "center", sortable: true, value: "Auto",width:"auto",class:"primary secondary--text text-center" },
    { text: "Thao tác", align: "center", sortable: false, value: "controller",width:"auto",class:"primary secondary--text text-center" },
]
const order_headers = [
    { text: "Giao dịch", align: "center", sortable: false,value: "uuid",width:"auto",class:"primary secondary--text text-center" },
    { text: "Campaigns", align: "center", sortable: true,value: "campaigns",width:"auto",class:"primary secondary--text text-center" },
    { text: "Proxy", align: "center", sortable: false,value: "proxy",width:"auto",class:"primary secondary--text text-center" },
    { text: "Last 4", align: "center", sortable: false,value: "last4",width:"auto",class:"primary secondary--text text-center" },
    { text: "Trạng thái", align: "center", sortable: true, value: "Status", width:"auto",class:"primary secondary--text text-center" },
    { text: "Thao tác", align: "center", sortable: true, value: "controller", width:"auto",class:"primary secondary--text text-center" },
]
export default {
    headers,
    order_headers,
    headers_mobile,
}