import { ResponseData, ResponseError, axiosInstance, Request } from '@/helpers'
const moduleName = 'teespring'
export const GetAll = () =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}`,req).then(ResponseData).catch(ResponseError)
}
export const Payout = ({id,amount}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/payout`,{id,amount},req).then(ResponseData).catch(ResponseError)
}
export const doVerifyPaypal = ({id}) =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}/verify_paypal/${id}`,req).then(ResponseData).catch(ResponseError)
}
export const SetWait = ({id}) =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}/wait/${id}`,req).then(ResponseData).catch(ResponseError)
}
export const SetProxy = ({id,Proxy}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/proxy/${id}`,{Proxy},req).then(ResponseData).catch(ResponseError)
}
export const RemoveProxy = ({id}) =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}/proxy/${id}`,req).then(ResponseData).catch(ResponseError)
}
export const doLogin = ({id,method}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/login/${id}`,{method},req).then(ResponseData).catch(ResponseError)
}
export const doPayout = ({id,currency}) =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}/payout/${id}/${currency}`,req).then(ResponseData).catch(ResponseError)
}
export const doFetchEmail = ({email,type}) =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}/mail/${type}/${email}`,req).then(ResponseData).catch(ResponseError)
}
export const doVerifyEmail = ({id,Link}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/open_link/${id}`,{Link},req).then(ResponseData).catch(ResponseError)
}
export const doSetAutoCheckBalance = ({id,Status}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/auto_balance/${id}`,{Status},req).then(ResponseData).catch(ResponseError)
}
export const doSetAutoWithdraw = ({id,AutoWithdraw}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/auto_withdraw/${id}`,{AutoWithdraw},req).then(ResponseData).catch(ResponseError)
}
export const doClearAutoWithdraw = ({id}) =>{
  const req = Request();
  return axiosInstance.delete(`/${moduleName}/auto_withdraw/${id}`,req).then(ResponseData).catch(ResponseError)
}
export const doMoveAccountOwner = ({Accounts,Create,Access}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/owner`,{Accounts,Create,Access},req).then(ResponseData).catch(ResponseError)
}
export const doGetPayoutHistory = ({id}) =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}/payout_history/${id}`,req).then(ResponseData).catch(ResponseError)
}
export const doAddOrderManual = ({id}) =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}/manual/${id}`,req).then(ResponseData).catch(ResponseError)
}
export default {
  GetAll,
  Payout,
  SetWait,
  doLogin,
  doPayout,
  SetProxy,
  doVerifyPaypal,
  doFetchEmail,
  doVerifyEmail,
  RemoveProxy,
  doSetAutoCheckBalance,
  doSetAutoWithdraw,
  doClearAutoWithdraw,
  doMoveAccountOwner,
  doGetPayoutHistory,
  doAddOrderManual,
}