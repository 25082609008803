export default {
    List(item){
        let controller = [
            {t:'Kiểm tra',c:'green',a:this.doCheck, i:'mdi-update'},
            {t:'Make Order',c:'blue',a:this.doOpenOrder, i:'mdi-heart-outline'},
        ]
        if(item.Status !== 'da_xu_ly' && item.Status !== 'cho_thanh_toan'){
            controller.splice(1,1);
        }
        if(item.Proxy){
            controller.push({t:'Login',c:'blue darken-4',a:this.doLoginOpen, i:'mdi-currency-usd'})
            if(item.Cookies && item.LastBalance !== '$0.00' && !item.AutoWithdraw){
                controller.push({t:'Đặt lịch Pay',c:'blue darken-5',a:this.doAutoPayoutOpen, i:'mdi-currency-usd'})
            }
            if(item.AutoWithdraw){
                controller.push({t:'Xóa lịch Pay',c:'blue darken-5',a:this.doAutoPayoutClear, i:'mdi-currency-usd'})
            }
            if(item.Cookies){
                controller.push({t:'History',c:'blue darken-1',a:this.doGetPayoutHistory, i:'mdi-history'})
            }
        }
        if(this.AccountOwner(item)){
            controller.push({t:'Email / Pass',c:'blue darken-2',a:this.doViewInfo, i:'mdi-magnify'})
            controller.push({t:'Cộng Order',c:'orange',a:this.doAddOrderManual, i:'mdi-basket-plus'});
        }
        if(item.Status === 'da_xu_ly'){
            controller.push({t:'Chờ Pay',c:'blue lighten-3',a:this.doWaiting, i:'mdi-update'})
        }
        if(!item.Proxy){
            controller.push({t:'Proxy',c:'indigo',a:this.doProxyOpen, i:'mdi-leak'})
        }else{
            controller.push({t:'Gỡ Proxy',c:'indigo',a:this.doRemoveProxy, i:'mdi-leak'})
        }
        return controller
    },
    Order(item){
        let controller = []
        if(item.Status === 'fail' && (item.Message !== 'Card Die' && item.Message !== 'Declined')){
            controller.push({t:'Retry',c:'orange',a:this.doRetryOrder, i:'mdi-update'});
        }
        if(item.Status === 'fail' || item.Status === 'ok'){
            controller.push({t:'Xóa',c:'red',a:this.doDeleteOrder, i:'mdi-delete'});
        }
        return controller
    }
}