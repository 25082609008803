import KTService, {RemoveThe} from '../../modules/kho_the.js';
import MyService from '../../modules/my.js';
import TeespringService, {
    doClearAutoWithdraw, doGetPayoutHistory,
    doSetAutoCheckBalabnce,
    doSetAutoCheckBalance,
    doSetAutoWithdraw
} from '../../modules/teespring.js';
import { Toast, DoiTien, ThoiGian, ThongBao, setStore, getStore, removeItem, hasNumber, TimeAgo } from '../'
import {randomFromArray} from '../arrays.js';
import sha256 from 'crypto-js/sha256';
import moment from 'moment-timezone'
export default class Teespring{
    doProxyOpen(item){
        this.CampData = Object.assign({},item);
        this.Box.BindIP = true;
    }
    doSetProxy(){
        const {_id,Proxy} = this.CampData;
        if(!_id || !Proxy) return ThongBao.Error('Dữ liệu không hợp lệ !!!');
        this.Loader.BindIP = true;
        TeespringService.SetProxy({id:_id,Proxy}).then(resp=>{
            if(resp.success){
                this.Box.BindIP = false;
                this.checking = true;
                this.doDownload();
            }
        }).finally(()=>{
            this.Loader.BindIP = false;
        })
    }
    doAddOrderManual(item){
        this.loading = true;
        TeespringService.doAddOrderManual({id:item._id}).then(()=>{
            this.doDownload();
        }).finally(()=>{
            this.loading = false;
        })
    }
    doUsedCard(){
        const card = this.KhoTheData;
        const KhoThe = this.doFindTheID(card);
      KTService.Used(KhoThe);
    }
    doRemoveCard(){
        const card = this.KhoTheData;
        const KhoThe = this.doFindTheID(card);
        KTService.RemoveThe({id:KhoThe});
    }
    doDownloadKT(){
        this.Loader.KhoThe = true;
        this.KhoThe = [];
        return KTService.GetNew().then(resp=>{
            const {data} = resp;
            if(data) this.KhoThe = data;
        }).finally(()=>{
            this.Loader.KhoThe = false;
        })
    }
    doKhoTheLoad(){
        const card = this.KhoTheData;
        const cardDetail = card.split('|');
        if(cardDetail.length >= 2){
            this.orderData.Card = cardDetail[0];
            if(cardDetail[1].split('/').length === 2){
                this.orderData.Expire = cardDetail[1];
            }else{
                this.orderData.Expire = cardDetail[1]+"/"+cardDetail[2];
            }
        }
        const KhoThe = this.doFindTheID(card);
        if(KhoThe !== undefined) this.orderData.KHOTHEID = KhoThe;
    }
    doFindTheID(data){
        let found = undefined;
        this.KhoThe.map(item=>{
            if(item.Data === data) found = item._id;
        });
        return found;
    }
    doFillAddress(){
        const Address = this.RandomAddressData.split('|');
        if(Address.length === 6){
            const newAddress = {
                Name:Address[0],
                Address:Address[1],
                City:Address[2],
                State:Address[3],
                Zip:Address[4],
                Phone:Address[5],
            }
            if(!hasNumber(newAddress.Address)){
                ThongBao.Error('Địa chỉ không hợp lệ !!!');
                return;
            }
            const {Card,Expire,Country,Proxy, DelayOrder} = this.orderData
            const OldData = {
                Card,
                Expire,
                Country,
                Proxy,
                DelayOrder,
            }
            this.orderData = Object.assign({},newAddress,OldData);
        }else{
            Toast.Error('Địa chỉ không hợp lệ !!!');
        }
    }
    doResetAddress(){
        this.orderData = {
            Country:'United States',
            Proxy:'PROXYGUYS',
        };
    }
    doRandomAddress(){
        this.Loader.RandomAddress = true;
        return this.RandomAddress().then(resp=>{
            const {data} = resp;
            if(data){
                this.DiaChiRandom = data;
            }else{
                ThongBao.Error('Không có địa chỉ được khởi tạo !!!');
            }
        }).finally(()=>{
            this.Loader.RandomAddress = false;
        })
    }
    doMergeOrder(){
        const orders = getStore('orders');
        let ordersList = [];
        if(orders){
            orders.forEach(item=>{
                if(item.Status && item.Date){
                    const now = moment().tz('Asia/Ho_Chi_Minh')
                    const itemData = moment(item.Date).tz('Asia/Ho_Chi_Minh').format();
                    const diff = now.diff(itemData,'days',true);
                    if(diff <= 1){
                        ordersList.push(item);
                        if(item.Status !== 'ok' && item.Status !== 'fail'){
                            const uuid = item.uuid;
                            this.OrderCheck = Object.assign(this.OrderCheck,{[uuid]:uuid});
                        }
                    }
                }
            });
            // if(OrderCheck.length > 0){
            //   Promise.all(OrderCheck).then(resp=>{
            //     const {uuid,Status} = resp;
            //     if(!Status){

            //     }
            //   })
            // }
        }
        this.orders = ordersList;
    }
    doCheckOrderExists(uuid){
        const orders = getStore('orders');
        return orders.filter(v=>v.uuid === uuid);
    }
    doCheckOrder(){
        const ListItems = Object.keys(this.OrderCheck).map((key) => this.OrderCheck[key])
        let CheckOrder = [];
        ListItems.forEach(uuid=>{
            if(uuid && this.doCheckOrderExists(uuid)){
                CheckOrder.push(this.CheckOrderTeespring({uuid}))
            }
        })
        Promise.all(CheckOrder).then(items=>{
            items.forEach(resp=>{
                const {uuid,Status,Message,Delay} = resp.data;
                if(!Status){
                    delete this.OrderCheck[uuid];
                    this.doRemoveOrder(uuid);
                }else{
                    this.doUpdateStatus(uuid,Status,Message,Delay);
                }
            });
        })
    }
    doOpenOrder(item){
        if(item.Status !== 'da_xu_ly' && item.Status !== 'cho_thanh_toan') return ThongBao.Error('Camp này không thể tạo giao dịch !!!');
        this.Box.Order = true;
        this.orderCamp = item._id;
        this.orderTitle = item.Title;
    }
    doDownload(){
        if(!this.checking) this.loading = true;
        MyService.Teespring().then(json=>{
            const {data} = json
            if(data){
                const hashed = sha256(JSON.stringify(data));
                if(hashed !== this.hashed){
                    console.log('Đã cập nhật dữ liệu mới !!!');
                    this.checking = false;
                }
                this.items = data
            }else{
                Toast.Error('Không thể tải dữ liệu !!!');
            }
        })
        .finally(()=>{
            this.loading = false;
        })
    }
    doCreate(){
        this.Loader.Add = true;
        const Url = this.addData.Url.trim().split('/')
        const UrlFormat = Url[Url.length-1]
        const req = Object.assign({},this.addData,{Url:UrlFormat})
        MyService.CreateTeespring(req).then(this.Callback).finally(()=>{
            this.Loader.Add = false;
        })
    }
    doOpenBoxAdd(){
        this.addData = {};
        this.Box.Add = true;
    }
    AccountOwner(item){
        if(item.Create.id === this.getUser.id) return true;
        return false;
    }
    doCheck(item){
        const index = (parseInt(item.index)-1);
        this.loading = true
        this.CheckTeespring({id:item._id}).then(resp=>{
            if(resp.data === true){
                this.checking = true;
                this.items[index].Status = 'dang_xu_ly';
                this.hashed = sha256(JSON.stringify(this.items));
            }
        }).finally(()=>{
            this.loading = false;
        })
    }
    doLogin(method){
        this.Loader.Login = true;
        const {_id} = this.CampData;
        TeespringService.doLogin({id:_id,method}).then(resp=>{
            const {data} = resp;
            if(data){
                this.CampData.Balance = data;
                this.doDownload();
            }
        }).finally(()=>{
            this.Loader.Login = false;
        })
    }
    doRequestPayout(){
        this.Loader.Login = true;
        const {_id,AutoWithdrawCurrency} = this.CampData;
        TeespringService.doPayout({id:_id,currency:AutoWithdrawCurrency}).then(resp=>{
            const {data} = resp;
            if(data){
                if(data !== 500){
                    this.Box.Login = false;
                    this.checking = true;
                }else{
                    ThongBao.Error('Lỗi 500 Request Payout');
                }
            }
        }).finally(()=>{
            this.Loader.Login = false;
        })
    }
    doLoginOpen(item){
        this.CampData = Object.assign({},item);
        this.Box.Login = true;
    }
    doViewInfo(item){
        this.CampData = Object.assign({},item);
        this.Box.Info = true;
    }
    doWaiting(item){
        const index = (parseInt(item.index)-1);
        this.loading = true
        this.SetTeespringWait({id:item._id}).then(resp=>{
            if(resp.data === true){
                this.checking = true;
                this.items[index].Status = 'cho_thanh_toan';
            }
        }).finally(()=>{
            this.loading = false;
        })
    }
    doDeleteOrder(item){
        const {uuid} = item;
        this.doRemoveOrder(uuid);
    }
    doRetryOrder(item){
        const {uuid} = item;
        this.loading = true;
        this.doUpdateStatus(uuid,'pending');
        this.RetryOrderTeespring({uuid}).then(resp=>{
            const {uuid,Status} = resp.data;
            if(!Status){
                delete this.OrderCheck[uuid];
                this.doRemoveOrder(uuid);
            }else{
                this.OrderCheck = Object.assign(this.OrderCheck,{[uuid]:uuid});
                this.doUpdateStatus(uuid,Status);
            }
        }).finally(()=>{
            this.loading = false;
        })
    }
    doUpdateStatus(uuid,status,Message,Delay){
        let updated = false;
        this.orders.forEach((item,index)=>{
            if(item.uuid === uuid){
                if(item.Status !== status){
                    this.orders[index].Status = status;
                    updated = true;
                }
                if(Message){
                    this.orders[index].Message = Message;
                }
                if(Delay){
                    this.orders[index].Delay = Delay;
                }
            }
        })
        if(updated === true){
            setStore('orders',this.orders);
        }
    }
    doRemoveOrder(uuid){
        const orders = getStore('orders');
        this.orders = orders.filter(v=>v.uuid !== uuid);
    }
    doOrder(){
        this.Loader.Order = true;
        if(this.orderData['DelayOrder'] && this.orderDelayTime){
            this.orderData['DelayOrder'] = this.orderDelayTime.format();
        }
        const request = Object.assign({CampID:this.orderCamp},this.orderData);
        MyService.CreateOrderTeespring(request).then(resp=>{
            const {data} = resp;
            const {Card} = this.orderData;
            const last4 = Card.slice(Card.length-4);
            if(data){
                this.orders.push({
                    uuid:data,
                    campaigns: this.orderTitle,
                    Status:'pending',
                    Date: moment().tz('Asia/Ho_Chi_Minh').format(),
                    proxy:(this.orderData.Proxy === 'PROXYGUYS') ? this.orderData.Proxy+'|'+this.orderData.State:this.orderData.Proxy,
                    last4:last4,
                    Delay:this.orderData.DelayOrder,
                });
                this.OrderCheck = Object.assign(this.OrderCheck,{[data]:data});
                this.Box.Order = false;
                this.tabs = 1;
                this.doResetAddress();
            }
        }).finally(()=>{
            this.Loader.Order = false;
        })
        // this.orders.push({})
    }
    Callback(resp){
        if(resp.success){
            this.doDownload();
            this.Box.Add = false;
        }
        this.loading = false;
    }
    doUpdateAutoCheckBalance(item){
        this.loading = true;
        const {_id,AutoCheckBalance} = item;
        const Status = (AutoCheckBalance.Status) ? false:true;
        TeespringService.doSetAutoCheckBalance({id:_id,Status}).then(this.Callback).finally(()=>{
            this.loading = false;
        })
    }
    doAutoPayoutOpen(item){
        this.CampData = Object.assign({},item);
        this.Box.AutoPayout = true;
    }
    doAutoPayout(){
        const {_id,AutoWithdraw} = this.CampData;
        const now = moment().tz('Asia/Ho_Chi_Minh');
        const AutoWithdrawTime = moment(AutoWithdraw,'DD-MM-YYYY H:m:s').tz('Asia/Ho_Chi_Minh');
        const diff = AutoWithdrawTime.diff(now,'minutes');
        if(diff < 0) return ThongBao.Error('Thời gian đặt rút phải ở tương lai !!!');
        this.Loader.AutoPayout = true;
        TeespringService.doSetAutoWithdraw({id:_id,AutoWithdraw:now.add(diff,'minutes')}).then(resp=>{
            const {success} = resp;
            if(success){
                this.Box.AutoPayout = false;
                this.doDownload();
            }
        }).finally(()=>{
            this.Loader.AutoPayout = false;
        })
    }
    doAutoPayoutClear(item){
        this.loading = true;
        const id = item._id;
        TeespringService.doClearAutoWithdraw({id}).finally(()=>{
            this.doDownload();
        })
    }
    doCheckDelay(){
        if(this.orderDelay > 0 && this.orderData.DelayOrder && this.Box.Order){
            const mins = (parseInt(this.orderDelay)) ? parseInt(this.orderDelay):0;
            this.orderDelayTime = moment.tz('Asia/Ho_Chi_Minh').add(mins,'minutes');
        }
    }
    doRandomAddressAndCard(){
        this.Loader.RandomAll = true;
        let requests = [this.doDownloadKT(),this.doRandomAddress()];
        Promise.all(requests).then(()=>{
            const Card = randomFromArray(this.KhoThe);
            const Address = randomFromArray(this.DiaChiRandomFixed);
            this.KhoTheData = Card.Data;
            this.RandomAddressData = Address.value;
            this.doKhoTheLoad();
            this.doFillAddress();
        }).finally(()=>{
            this.Loader.RandomAll = false;
        });
    }
    doGetPayoutHistory(item){
        this.Loader.PayoutHistory = true;
        this.Box.PayoutHistory = true;
        this.CampData = {
            Title:item.Title,
            History:'<span class="text-h6 orange--text">Đang lấy dữ liệu...</span>'
        }
        TeespringService.doGetPayoutHistory({id:item._id}).then(resp=>{
            const {data} = resp;
            if(data){
                this.CampData.History = data;
            }else{
                this.CampData.History = '<span class="text-h6 red--text">Không có dữ liệu !!!</span>';
            }
        }).finally(()=>{
            this.Loader.PayoutHistory = false;
        })
    }
}